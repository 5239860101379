import React, { useRef, useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { Analytics } from "@vercel/analytics/react";
import { Document, Page, pdfjs } from "react-pdf";
import { Helmet } from "react-helmet";
import "./styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PageProps {
  pageNumber: number;
  width: number;
  height: number;
}

const PageComponent = React.forwardRef<HTMLDivElement, PageProps>(
  ({ pageNumber, width, height }, ref) => {
    return (
      <div className="demoPage" ref={ref} style={{ width, height }}>
        <Page
          pageNumber={pageNumber}
          width={width}
          height={height}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </div>
    );
  }
);

const Book = () => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageSize, setPageSize] = useState({ width: 583, height: 592 });
  const book = useRef<any>(null);

  useEffect(() => {
    const aspectRatio = 592 / 583;
    let width = Math.min(583, window.innerWidth - 40);
    let height = width * aspectRatio;

    if (height > window.innerHeight - 40) {
      height = window.innerHeight - 40;
      width = height / aspectRatio;
    }

    setPageSize({ width, height });

    // const updateDimensions = () => {};

    // window.addEventListener("resize", updateDimensions);

    // return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const nextPage = () => {
    book.current.pageFlip().flipNext();
  };

  const prevPage = () => {
    book.current.pageFlip().flipPrev();
  };

  return (
    <div>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href="/favicon-48x48.png"
          sizes="48x48"
        />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content="#000000" />
        <title
          dangerouslySetInnerHTML={{
            __html: "Light",
          }}
        />
      </Helmet>
      <Analytics />
      <Document
        file="/light.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
        loading="Loading book..."
      >
        {numPages && (
          // @ts-ignore
          <HTMLFlipBook
            width={pageSize.width}
            height={pageSize.height}
            size="fixed"
            minWidth={300}
            maxWidth={1000}
            minHeight={400}
            maxHeight={1533}
            maxShadowOpacity={0.5}
            showCover={true}
            mobileScrollSupport={true}
            ref={book}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <PageComponent
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={pageSize.width}
                height={pageSize.height}
              />
            ))}
          </HTMLFlipBook>
        )}
      </Document>
      <div className="controls">
        <button onClick={prevPage}>Previous</button>
        <button onClick={nextPage}>Next</button>
      </div>
    </div>
  );
};

export default Book;
